


// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { Link, useParams } from "react-router-dom";
// import dateFormat from "dateformat";

// const EventDetails = () => {
//   const [eventDetail, setEventDetail] = useState([]);
//   const [totalAmount, setTotalAmount] = useState(0);
//   const [totalDonar, setTotalDonar] = useState(0);
//   const [loader, setLoader] = useState(false);
//   const [currentImgIndex, setCurrentImgIndex] = useState(0);
  

//   const { id } = useParams();

//   useEffect(() => {
//     const DetailFun = async () => {
//       setLoader(true);
//       await axios
//         .get(`${process.env.REACT_APP_BASE_URL}/v1/getallevent`)
//         .then((res) => {
//           const data = res?.data;
//           const eventDetail = data?.filter((item) => item._id == id);

//           setEventDetail(eventDetail);
//           setLoader(false);
//         })
//         .catch((error) => {
//           console.log("something went wrong" + error);
//         });
//     };
//     DetailFun();
//   }, [id]);

//   const totalPaymentFun = async (eventId) => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BASE_URL}/v1/show-donation`
//       );
//       const allData = response?.data;

//       if (!allData) {
//         console.log("No data received.");
//         return;
//       }

//       const filteredData = allData?.data?.filter(
//         (item) => item?.eventId === id
//       );

//       setTotalDonar(filteredData.length);
//       const totalAmount = filteredData?.reduce(
//         (sum, item) => sum + (item?.donatedAmount || 0),
//         0
//       );

//       setTotalAmount(totalAmount);
//     } catch (error) {
//       console.error("Something went wrong:", error);
//     }
//   };

//   useEffect(() => {
//     totalPaymentFun();
//   }, []);

//   // Handle Next and Previous Image Change
//   const goToNextImage = () => {
//     setCurrentImgIndex((prevIndex) =>
//       prevIndex === eventDetail[0]?.postimg?.length - 1 ? 0 : prevIndex + 1
//     );
//   };

//   const goToPreviousImage = () => {
//     setCurrentImgIndex((prevIndex) =>
//       prevIndex === 0 ? eventDetail[0]?.postimg?.length - 1 : prevIndex - 1
//     );
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentImgIndex((prevIndex) =>
//         prevIndex === eventDetail[0]?.postimg?.length - 1 ? 0 : prevIndex + 1
//       );
//     }, 3000); // Change image every 1 second

//     return () => clearInterval(interval); // Cleanup interval on component unmount
//   }, [eventDetail]);


//   const handleShare = () => {
//     const url = window.location.href;

//     if (navigator.share) {
//       navigator
//         .share({
//           title: "Check this out!",
//           url: url,
//         })
//         .then(() => console.log("Share successful"))
//         .catch((error) => console.error("Share failed:", error));
//     } else {
//       alert("Sharing is not supported in this browser. Copy this URL: " + url);
//     }
//   };

//   return (
//     <div>
//       {loader ? (
//         <span className="font-bold text-center ml-4 text-md text-blue-500 p-2">
//           Loading...
//         </span>
//       ) : (
//         ""
//       )}
//       {eventDetail?.map((res, i) => (
//         <div className="flex flex-col justify-between items-start md:flex-row" key={i}>
//           {/* Image Carousel Section */}
//           <div className="mt-0.5 grid grid-cols-1 justify-center m-auto relative md:w-1/2">
//             {res?.postimg?.length > 0 && (
//               <img
//                 src={res?.postimg[currentImgIndex]} // Dynamically changing image
//                 alt="event"
//                 className="p-1 h-80 w-full cursor-pointer -z-10"
//               />
//             )}

//             {/* Next and Previous Buttons */}
//             <button
//               onClick={goToPreviousImage}
//               className="absolute left-2 top-1/2 transform -translate-y-1/2 text-white text-2xl hover:text-gray-300"
//             >
//               {'<'}
//             </button>

//             <button
//               onClick={goToNextImage}
//               className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white text-2xl hover:text-gray-300"
//             >
//               {'>'}
//             </button>
//           </div>

//           {/* Event Details */}
//           <div className="lg:mt-2 shadow-md md:w-1/2 lg:h-80 lg:shadow-none">
//             <div className="flex-col md:flex justify-between items-center pb-2 bg-gray-100">
//               <p className="bg-gray-100 ml-0 p-2 font-bold text-lg text-center">
//                 {res?.title}
//               </p>
//               <div className="flex justify-between items-center">
//                 <p className="mx-1 ml-4 text-xs">
//                   Created by{" "}
//                   <i className="text-teal-600 font-semibold">{res?.username}</i>
//                 </p>
//                 <img
//                   src={res?.userprofile}
//                   alt="user profile"
//                   className="h-10 w-10 rounded-full mt-2 mr-2"
//                 />
//               </div>
//             </div>
//             <div className="flex-col md:flex justify-between items-center pb-2 bg-gray-100 p-4">
//               {/* <p className="my-2 font-semibold text-teal-700 text-xs">
//                 <b>Date:- </b>
//                 {dateFormat(res?.eventdate, "dddd, mmmm dS, yyyy")}{" "}
//                 <b>Time:- </b>
//                 {res?.eventtime}
//               </p> */}
//               <p className="my-2 font-semibold text-blue-800 text-sm">
//                 <b>Location:-</b> {res?.location}
//               </p>
//               <div className="flex justify-between items-center mt-4">
//                 {res?.status ? (
//                   <>
//                     <Link
//                       to={`/payment/${res?._id}`}
//                       className="bg-red-500 text-sm lg:mx-2 font-semibold cursor-pointer p-1 px-2 rounded-sm text-white hover:bg-red-600"
//                     >
//                       Donate Now
//                     </Link>
//                     <p
//                       className="bg-green-500 font-semibold text-sm cursor-pointer p-1 px-2 rounded-sm text-white hover:bg-green-600"
//                       onClick={handleShare}
//                     >
//                       <span className="bg-green-500 py-1 px-2 text-white font-semibold rounded-sm">
//                         Share Event
//                       </span>
//                     </p>
//                   </>
//                 ) : (
//                   <>
//                     <p className="my-2 font-semibold text-lg text-red-500">
//                       This Event has been completed!
//                     </p>
//                     <p
//                       className="bg-green-500 font-semibold text-sm cursor-pointer p-1 rounded-sm text-white hover:bg-green-600"
//                       onClick={handleShare}
//                     >
//                       <p className="bg-green-500 py-1 px-2 text-white font-semibold rounded-sm">
//                         Share
//                       </p>
//                     </p>
//                   </>
//                 )}
//               </div>
//               <div className="flex justify-between items-center">
//                 <p className="lg:ml-10 pt-2 text-sm font-semibold text-cyan-500">
//                   Total Donation :{" "}
//                   <span className="text-cyan-600">₹ {totalAmount}</span>
//                 </p>
//                 <p className="lg:ml-10 pt-2 text-sm font-semibold text-cyan-500">
//                   Total Donor's :{" "}
//                   <span className="text-cyan-600">({totalDonar})</span>
//                 </p>
//               </div>
//             </div>

//             <p className="p-3 mb-4 text-sm font-semibold">
//               <span className="text-teal-700 font-bold">Description :-</span>{" "}
//               {res?.disc}
//             </p>
        
//           </div>
         
//         </div>
        
//       ))}
//     </div>
//   );
// };

// export default EventDetails;






import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import dateFormat from "dateformat";

const EventDetails = () => {
  const [eventDetail, setEventDetail] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalDonar, setTotalDonar] = useState(0);
  const [loader, setLoader] = useState(false);
  const [currentImgIndex, setCurrentImgIndex] = useState(0);

  const { id } = useParams();

  useEffect(() => {
    const DetailFun = async () => {
      setLoader(true);
      await axios
        .get(`${process.env.REACT_APP_BASE_URL}/v1/getallevent`)
        .then((res) => {
          const data = res?.data;
          const eventDetail = data?.filter((item) => item._id == id);

          setEventDetail(eventDetail);
          setLoader(false);
        })
        .catch((error) => {
          console.log("something went wrong" + error);
        });
    };
    DetailFun();
  }, [id]);

  const totalPaymentFun = async (eventId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/show-donation`
      );
      const allData = response?.data;

      if (!allData) {
        console.log("No data received.");
        return;
      }

      const filteredData = allData?.data?.filter(
        (item) => item?.eventId === id
      );

      setTotalDonar(filteredData.length);
      const totalAmount = filteredData?.reduce(
        (sum, item) => sum + (item?.donatedAmount || 0),
        0
      );

      setTotalAmount(totalAmount);
    } catch (error) {
      console.error("Something went wrong:", error);
    }
  };

  useEffect(() => {
    totalPaymentFun();
  }, []);

  // Handle Next and Previous Image Change
  const goToNextImage = () => {
    setCurrentImgIndex((prevIndex) =>
      prevIndex === eventDetail[0]?.postimg?.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPreviousImage = () => {
    setCurrentImgIndex((prevIndex) =>
      prevIndex === 0 ? eventDetail[0]?.postimg?.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImgIndex((prevIndex) =>
        prevIndex === eventDetail[0]?.postimg?.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [eventDetail]);

  // Handle Share with Event Details in the URL
  const handleShare = () => {
    const event = eventDetail[0]; // Assuming there's always one event
    const currentUrl = window.location.href;

    // Construct the share URL with event details as query parameters
    const shareUrl = `${currentUrl}?title=${encodeURIComponent(event?.title)}`;

    // Use the navigator.share API to share the link
    if (navigator.share) {
      navigator
        .share({
          title: event?.title,
          text: `${event?.title} at ${event?.location}`,
          url: shareUrl, // URL with event details
        })
        .then(() => console.log("Share successful"))
        .catch((error) => console.error("Share failed:", error));
    } else {
      // If the browser doesn't support sharing, show the URL
      alert("Sharing is not supported in this browser. Copy this URL: " + shareUrl);
    }
  };

  return (
    <div>
      {loader ? (
        <span className="font-bold text-center ml-4 text-md text-blue-500 p-2">
          Loading...
        </span>
      ) : (
        ""
      )}
      {eventDetail?.map((res, i) => (
        <div className="flex flex-col justify-between items-start md:flex-row" key={i}>
          {/* Image Carousel Section */}
          <div className="mt-0.5 grid grid-cols-1 justify-center m-auto relative md:w-1/2">
            {res?.postimg?.length > 0 && (
              <img
                src={res?.postimg[currentImgIndex]} // Dynamically changing image
                alt="event"
                className="p-1 h-80 w-full cursor-pointer -z-10"
              />
            )}

            {/* Next and Previous Buttons */}
            <button
              onClick={goToPreviousImage}
              className="absolute left-2 top-1/2 transform -translate-y-1/2 text-white text-2xl hover:text-gray-300"
            >
              {"<"}
            </button>

            <button
              onClick={goToNextImage}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white text-2xl hover:text-gray-300"
            >
              {">"}
            </button>
          </div>

          {/* Event Details */}
          <div className="lg:mt-2 shadow-md md:w-1/2 lg:h-80 lg:shadow-none">
            <div className="flex-col md:flex justify-between items-center pb-2 bg-gray-100">
              <p className="bg-gray-100 ml-0 p-2 font-bold text-lg text-center">
                {res?.title}
              </p>
              <div className="flex justify-between items-center">
                <p className="mx-1 ml-4 text-xs">
                  Created by{" "}
                  <i className="text-teal-600 font-semibold">{res?.username}</i>
                </p>
                <img
                  src={res?.userprofile}
                  alt="user profile"
                  className="h-10 w-10 rounded-full mt-2 mr-2"
                />
              </div>
            </div>
            <div className="flex-col md:flex justify-between items-center pb-2 bg-gray-100 p-4">
              <p className="my-2 font-semibold text-blue-800 text-sm">
                <b>Location:-</b> {res?.location}
              </p>
              <div className="flex justify-between items-center mt-4">
                {res?.status ? (
                  <>
                    <Link
                      to={`/payment/${res?._id}`}
                      className="bg-red-500 text-sm lg:mx-2 font-semibold cursor-pointer p-1 px-2 rounded-sm text-white hover:bg-red-600"
                    >
                      Donate Now
                    </Link>
                    <p
                      className="bg-green-500 font-semibold text-sm cursor-pointer p-1 px-2 rounded-sm text-white hover:bg-green-600"
                      onClick={handleShare}
                    >
                      <span className="bg-green-500 py-1 px-2 text-white font-semibold rounded-sm">
                        Share Event
                      </span>
                    </p>
                  </>
                ) : (
                  <>
                    <p className="my-2 font-semibold text-lg text-red-500">
                      This Event has been completed!
                    </p>
                    <p
                      className="bg-green-500 font-semibold text-sm cursor-pointer p-1 rounded-sm text-white hover:bg-green-600"
                      onClick={handleShare}
                    >
                      <p className="bg-green-500 py-1 px-2 text-white font-semibold rounded-sm">
                        Share
                      </p>
                    </p>
                  </>
                )}
              </div>
              <div className="flex justify-between items-center">
                <p className="lg:ml-10 pt-2 text-sm font-semibold text-cyan-500">
                  Total Donation :{" "}
                  <span className="text-cyan-600">₹ {totalAmount}</span>
                </p>
                <p className="lg:ml-10 pt-2 text-sm font-semibold text-cyan-500">
                  Total Donor's :{" "}
                  <span className="text-cyan-600">({totalDonar})</span>
                </p>
              </div>
            </div>

            <p className="p-3 mb-4 text-sm font-semibold">
              <span className="text-teal-700 font-bold">Description :-</span>{" "}
              {res?.disc}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EventDetails;

